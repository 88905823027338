import { createSlice } from '@reduxjs/toolkit';
import {
  getBanners,
  getArticleMegaMenu,
  getUserStatus,
  getArticleCategoryIds,
} from '~store/app/thunks';

const initialState = {
  activeIndexMenu: '',
  currentOpenMegaMenu: false,
  currentOpenProfileMenu: false,
  currentOpenMegaMenuMobile: false,
  postCategories: [],
  modal: {
    loginModal: false,
    banner: false,
    searchBar: false,
    shareModal: false,
    notFoundModal: false,
  },
  status: {
    getBannersFetch: 'idle',
    getArticleMenuFetch: false,
    getArticleMenuError: null,
    getUserStatusFetch: false,
    getUserStatusError: null,
    getArticleCategoryIdsFetch: false,
    getArticleCategoryIdsError: false,
  },
  bannerData: null,
  bannerSession: [],
  articleMenu: {
    whatsNewData: [],
    whatsGoodData: [],
    whatsFunData: [],
  },
  notFoundType: '404',
  currentTopHeaderHeight: 0,
  currentMainHeaderHeigth: 0,
  resizeLogo: false,
  shareModalAttribute: null,
  scrollDirection: '',
  appIsReadyToLoad: false,
};

const { reducer, actions } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveIndexMenu(state, action) {
      state.activeIndexMenu = action.payload;
    },
    setOpenMegaMenuStatus(state, action) {
      state.currentOpenMegaMenu = action.payload;
    },
    setOpenMegaMenuMobileStatus(state, action) {
      state.currentOpenMegaMenuMobile = action.payload;
    },
    setOpenProfileMenuStatus(state, action) {
      state.currentOpenProfileMenu = action.payload;
    },
    setModalStatus(state, { name, value }) {
      state.modal[name] = value;
    },
    setBannerSession(state, action) {
      const res = state.bannerSession.concat(action.payload);
      state.bannerSession = [...new Set(res)];
    },
    showNotFoundModal(state, action) {
      let errorType;
      if (action.payload?.includes('404')) {
        errorType = '404';
      } else if (action.payload?.includes('503')) {
        errorType = '503';
      } else {
        errorType = '500';
      }
      state.notFoundType = errorType;
      state.modal.notFoundModal = true;
    },
    setHeaderGlobalHeigth(state, action) {
      if (action.payload.type === 'top') {
        state.currentTopHeaderHeight = action.payload.height;
      } else {
        state.currentMainHeaderHeigth = action.payload.height;
      }
    },
    setResizeLogo(state, action) {
      state.resizeLogo = action.payload;
    },
    setShareModalAttribute(state, action) {
      state.shareModalAttribute = action.payload;
    },
    setScrollDirection(state, action) {
      state.scrollDirection = action.payload;
    },
    setAppReadyToLoad(state, action) {
      state.appIsReadyToLoad = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanners.pending, (state) => {
        state.status.getBannersFetch = 'loading';
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.status.getBannersFetch = 'error';
      })
      .addCase(getBanners.fulfilled, (state, { payload }) => {
        state.status.getBannersFetch = 'finished';
        state.bannerData = payload.data;
        state.status.getBannersFetch = 'idle';
      })
      .addCase(getArticleMegaMenu.pending, (state) => {
        state.status.getArticleMenuFetch = true;
        state.status.getArticleMenuError = null;
      })
      .addCase(getArticleMegaMenu.rejected, (state, action) => {
        state.status.getArticleMenuFetch = false;
        state.status.getArticleMenuError = action?.error?.message;
      })
      .addCase(getArticleMegaMenu.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          const locationMap = {
            'whats-new': 'whatsNewData',
            'whats-good': 'whatsGoodData',
            'whats-fun': 'whatsFunData',
          };
          const locationType = locationMap[payload.type];
          state.articleMenu[locationType] = payload.data.data.posts;
        };
        updateData(state, payload);
        state.status.getArticleMenuFetch = false;
      })
      .addCase(getArticleCategoryIds.pending, (state) => {
        state.status.getArticleCategoryIdsFetch = true;
        state.status.getArticleCategoryIdsError = null;
      })
      .addCase(getArticleCategoryIds.rejected, (state, action) => {
        state.status.getArticleCategoryIdsFetch = false;
        state.status.getArticleCategoryIdsError = action?.error?.message;
      })
      .addCase(getArticleCategoryIds.fulfilled, (state, { payload }) => {
        const updateData = (state, payload) => {
          state.postCategories = payload.data.data;
          state.status.getArticleCategoryIdsFetch = false;
        };
        updateData(state, payload);
        state.status.getArticleMenuFetch = false;
      })
      .addCase(getUserStatus.pending, (state) => {
        state.status.getUserStatusFetch = true;
      })
      .addCase(getUserStatus.rejected, (state, action) => {
        state.status.getUserStatusFetch = false;
        state.status.getUserStatusError = action?.error?.message;
      })
      .addCase(getUserStatus.fulfilled, (state, { payload }) => {
        state.status.getUserStatusFetch = false;
      });
  },
});

export const {
  setActiveIndexMenu,
  setOpenMegaMenuStatus,
  setOpenProfileMenuStatus,
  setOpenMegaMenuMobileStatus,
  setBannerSession,
  showNotFoundModal,
  setHeaderGlobalHeigth,
  setResizeLogo,
  setShareModalAttribute,
  setScrollDirection,
} = actions;

export const selectActiveIndexMenu = (state) => state.app.activeIndexMenu;
export const selectCurrentOpenMegaMenu = (state) =>
  state.app.currentOpenMegaMenu;
export const selectCurrentOpenProfileMenu = (state) =>
  state.app.currentOpenProfileMenu;
export const selectCurrentOpenMegaMenuMobile = (state) =>
  state.app.currentOpenMegaMenuMobile;
export const selectModalStatus = (type) => {
  return (state) => state.app.modal[type];
};
export const selectFetchStatus = (type) => {
  return (state) => state.app.status[type];
};
export const toggleModalStatus = (name, value) => {
  return {
    type: 'app/setModalStatus',
    name,
    value,
  };
};
export const selectBannerData = (state) => state.app.bannerData;
export const selectBannerSession = (state) => state.app.bannerSession;

export const selectAppState = (type) => {
  return (state) => state.app[type];
};

export default reducer;
